// import custom fonts
@font-face {
  font-family: 'Apercu Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/apercu/apercu-bold.woff2') format('woff2'), url('./assets/fonts/apercu/apercu-bold.woff') format('woff');
}

@font-face {
  font-family: 'Apercu Light';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/apercu/apercu-light.woff2') format('woff2'), url('./assets/fonts/apercu/apercu-light.woff') format('woff');
}

@font-face {
  font-family: 'Apercu Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/apercu/apercu-pro-regular.woff2') format('woff2'), url('./assets/fonts/apercu/apercu-pro-regular.woff') format('woff');
}

@font-face {
  font-family: 'Seven Segment';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Seven Segment.woff2') format('woff2'), url('./assets/fonts/Seven Segment.woff') format('woff');
}

html {
  height: 100%;
}

body {
  background:
    linear-gradient(289.68deg, rgb(55 11 110 / 50%) 2.52%, rgb(47 155 255 / 50%) 95.2%),
    linear-gradient(0deg, #370b6e, #370b6e),
    #000;
  font-family: 'Apercu Pro Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

#root {
  height: 100%;
}
