.Multizone {
  display: grid;
  grid-template-columns: 20% 90%; // Creates a sidebar and main content ratio of 1:3
  gap: 10px; // Adjust the gap between the sidebar and main content as needed
  height: 100%; // Adjust the height as needed
  width: 100%;

  // Make entire screen
  // height: 100vh;
  // width: 100vw;

  > .sidebar {
    // width: 100%;
    // height: 100%;
    padding: 20px; // Example padding
    // width: 10%;
    // height: 10%;
    transform: scale(0.5);
    justify-self: center;
    align-self: center;
  }

  > .main-content {
    padding: 20px; // Example padding
  }

  video {
    width: 80%;
    height: 80%;
    border: none; /* Optional: Removes the default iframe border */
  }
}
