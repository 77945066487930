@font-face {
  font-family: 'Apercu Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/apercu-bold.woff') format('woff2'), url('./assets/fonts/apercu-bold.woff') format('woff');
}

@font-face {
  font-family: 'Apercu Light';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/apercu-light.woff2') format('woff2'), url('./assets/fonts/apercu-light.woff') format('woff');
}

@font-face {
  font-family: 'Apercu Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/apercu-pro-regular.woff2') format('woff2'), url('./assets/fonts/apercu-pro-regular.woff') format('woff');
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  > .title {
    color: #fff;
    font-size: 75px;
    height: 110px;
    max-width: calc(100% - 100px);
    overflow: hidden;
    padding-top: 50px;
    text-overflow: ellipsis;
  }

  > .widget {
    flex: 1;
    width: 100%;
  }

  > .splashBar {
    height: 140px;
    width: 100%;

    &.isPortrait {
      height: 54px;
    }

    &.draw {
      align-items: center;
      background: rgba(#000, 0.4);
      color: #fff;
      display: flex;
      font-size: 50px;
      justify-content: center;
    }
  }
}
