.SevenSegmentDigit {
  font-family: 'Seven Segment';
  font-size: 350px;

  &.medium {
    font-size: 250px;
  }

  &.small {
    font-size: 100px;
  }

  &.normaliseHeight {
    bottom: 60px;
    position: relative;

    &.medium {
      bottom: 50px;
    }

    &.small {
      bottom: 20px;
    }
  }

  &.normaliseWidth {
    display: flex;
    justify-content: flex-end;
  }
}
