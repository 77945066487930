.CalendarWorkWeek {
  box-sizing: border-box;
  color: white;
  font-family: 'Apercu Pro Regular';
  height: 100%;
  padding: 50px 80px;
  width: 100%;

  > .mainContainer {
    display: flex;
    flex-direction: row;
    height: 980px;
    width: 1760px;

    &.isPortrait {
      flex-direction: column;
      height: 1820px;
      width: 920px;
    }

    > .errorContainer {
      display: flex;
      flex-flow: column wrap;
      height: 920px;
      justify-content: center;
      width: 1250px;

      &.isPortrait {
        height: 1100px;
        width: 920px;
      }

      > .error {
        margin: 0 0 60px;
        text-align: center;

        > .errorImage {
          height: 48px;
          margin: 0 0 20px;
          width: 48px;
        }

        > .errorMessage {
          font-size: 30px;
        }
      }
    }

    > .header {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 1000px;
      justify-content: center;
      line-height: 1;
      padding-right: 60px;
      text-align: center;
      width: 510px;

      &.isPortrait {
        height: 375px;
        width: 920px;
      }

      > .headerContentWrapper {
        > .date {
          font-size: 48px;
          margin: 0 0 60px;
        }

        > .day {
          font-size: 60px;
          margin: 0 0 10px;
        }

        > .time {
          font-size: 80px;
          font-weight: 700;
        }
      }
    }

    > .nonHeader {
      display: flex;
      flex-direction: column;
      width: 1250px;

      &.isPortrait {
        width: 920px;
      }

      > .line {
        border-bottom: 2px solid white;
      }

      > .noEventsContainer {
        display: flex;
        flex-direction: column;
        height: 800px;
        justify-content: center;
        width: 1250px;

        &.isPortrait {
          height: 1100px;
          width: 920px;
        }

        > .noEvents {
          text-align: center;

          > .noEventsImage {
            height: 48px;
            margin: 0 0 20px;
            width: 48px;
          }

          > .noEventsMessage {
            font-size: 30px;
          }
        }
      }

      > .subtitle {
        font-size: 30px;
        padding: 30px 0;
      }

      > .tableContainer {
        height: 750px;
        width: 1250px;

        &.isPortrait {
          height: 1360px;
          width: 920px;
        }

        > .spacing {
          height: 30px;
          width: 1760px;
        }

        > .table {
          border-spacing: 10px;
          display: flex;
          flex-direction: row;
          height: 720px;
          table-layout: fixed;
          width: 1250px;

          &.isPortrait {
            flex-direction: column;
            height: 1360px;
            width: 920px;
          }

          > .day {
            width: 250px;

            &.isPortrait {
              width: 920px;
            }

            &.showBorder {
              border-right: 1px solid white;

              &.isPortrait {
                border-bottom: 1px solid white;
                border-right: none;
              }
            }

            &.today {
              background: #0082ff;
              border: 1px solid #0082ff;
              border-radius: 4px;
            }

            > .dayContent {
              &.faded {
                opacity: 0.5;
              }

              > .dateHeader {
                align-items: center;
                display: flex;
                flex-direction: column;
                font-size: 32px;
                height: 115px;
                justify-content: center;
                text-align: center;

                &.isPortrait {
                  flex-direction: row;
                  font-size: 24px;
                  height: 55px;
                  justify-content: left;
                  padding: 0 0 0 11px;
                  text-align: left;
                }

                > .dayOfWeek.isPortrait {
                  margin: 0 8px 0 0;
                }
              }

              > .eventsContainer {
                padding: 6px;

                &.isPortrait {
                  display: flex;
                  flex-flow: row wrap;
                  min-height: 70px;
                  padding: 4px 4px 4px 11px;
                }

                > .event {
                  background: rgba(255 255 255 / 20%);
                  border-radius: 4px;
                  margin: 0 0 7px;
                  padding: 11px;

                  &.isPortrait {
                    margin: 0 7px 7px 0;
                    width: 197px;
                  }

                  &.now {
                    background: white;
                    color: #0082ff;
                  }

                  > .eventTime {
                    font-size: 14px;
                  }

                  > .eventTitle {
                    font-size: 18px;
                    margin: 0 0 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
