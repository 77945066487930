.CalendarMonth {
  box-sizing: border-box;
  color: white;
  font-family: 'Apercu Pro Regular';
  font-weight: 500;
  height: 100%;
  padding: 50px 70px;
  width: 100%;

  &.isPortrait {
    padding: 50px 55px;
  }

  > .mainContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    > .errorContainer {
      display: flex;
      flex-flow: column wrap;
      height: 880px;
      justify-content: center;
      width: 1760px;

      &.isPortrait {
        height: 1200px;
        width: 930px;
      }

      > .error {
        margin: 0 0 60px;
        text-align: center;

        > .errorImage {
          height: 48px;
          margin: 0 0 20px;
          width: 48px;
        }

        > .errorMessage {
          font-size: 30px;
        }
      }
    }

    > .header {
      display: flex;
      flex-direction: row;
      height: 60px;
      line-height: 1;
      margin: 0 0 40px;
      width: 1760px;

      > .time {
        font-size: 60px;
        font-weight: 700;
        padding: 8px 0 0;
        white-space: nowrap;
        width: 260px;
      }

      > .date {
        font-size: 30px;
        margin-left: 80px;
        padding: 32px 0 0;
      }

      > .subtitle {
        font-size: 20px;
        margin-left: 100px;
        padding: 40px 0 0;
      }
    }

    > .headerPortrait {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 500px;
      justify-content: center;
      line-height: 1;
      margin: 0 0 40px;
      text-align: center;

      > .headerPortraitContentWrapper {
        > .date {
          font-size: 60px;
          margin: 0 0 20px;
        }

        > .time {
          font-size: 80px;
          font-weight: 700;
        }
      }
    }

    > .line {
      border-bottom: 2px solid white;
      width: 1760px;

      &.isPortrait {
        width: 910px;
      }
    }

    > .noEventsContainer {
      display: flex;
      flex-direction: column;
      height: 800px;
      justify-content: center;
      width: 1760px;

      &.isPortrait {
        height: 1150px;
        width: 900px;
      }

      > .noEvents {
        text-align: center;

        > .noEventsImage {
          height: 48px;
          margin: 0 0 20px;
          width: 48px;
        }

        > .noEventsMessage {
          font-size: 30px;
        }
      }
    }

    > .portraitSubtitle {
      font-size: 20px;
      margin: 0 0 20px;
      text-align: left;
      width: 910px;
    }

    > .tableContainer {
      display: flex;
      height: 750px;
      justify-content: center;
      width: 1780px;

      &.isPortrait {
        height: 1080px;
        width: 930px;
      }

      > .table {
        border-spacing: 10px;
        height: 750px;
        table-layout: fixed;
        width: 1780px;

        &.isPortrait {
          width: 930px;
        }

        > .tableBody {
          height: 100%;
          width: 100%;

          > .tableHeader {
            height: 40px;

            > .tableHeaderCell {
              width: 240px;

              &.isPortrait {
                width: 120px;
              }
            }
          }

          > .tableRow {
            height: 105px;

            &.fiveRows {
              height: 125px;
            }

            &.fourRows {
              height: 155px;
            }

            &.isPortrait {
              height: 160px;
            }

            > .tableRowCell {
              border-top: 3px solid white;
              padding: 5px;
              vertical-align: top;
              width: 240px;

              &.faded {
                border-top: 3px solid rgba(255 255 255 / 50%);
              }

              &.isPortrait {
                width: 112px;
              }

              &.today {
                background: #0082ff;
                border: 1px solid #0082ff;
                border-radius: 4px;
              }

              > .calendarCell {
                &.faded {
                  opacity: 0.5;
                }

                > .andMore {
                  font-size: 11px;
                  margin: 10px 0 0;
                  text-decoration: underline;

                  &.fiveRows {
                    font-size: 13px;
                  }

                  &.fourRows {
                    font-size: 16px;
                  }
                }

                > .cellNumber {
                  font-size: 20px;
                  padding: 0 0 5px;

                  &.fiveRows {
                    font-size: 24px;
                  }

                  &.fourRows {
                    font-size: 30px;
                  }
                }

                > .event {
                  background: rgba(255 255 255 / 20%);
                  border-radius: 4px;
                  font-size: 14px;
                  margin: 0 0 4px;
                  overflow: hidden;
                  padding: 4px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 220px;

                  &.fiveRows {
                    font-size: 18px;
                    margin: 0 0 5px;
                  }

                  &.fourRows {
                    font-size: 22px;
                    margin: 0 0 7px;
                  }

                  &.isPortrait {
                    -webkit-box-orient: vertical;
                    box-sizing: border-box;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-height: 18px;
                    max-height: 40px;
                    white-space: pre-line;
                    width: 110px;
                  }

                  &.now {
                    background: white;
                    color: #0082ff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
