$business-color: #0d739e;
$entertainment-color: #5a12b5;
$nation-color: #0082fa;
$science-color: #ee0072;
$sports-color: #d26500;
$world-color: #31ac3e;

@function background-gradient($color, $direction) {
  @return linear-gradient(to $direction, $color 41%, rgba(0, 0, 0, 0%) 58%);
}

.News {
  font-family: 'Apercu Bold';
  height: 100%;
  width: 100%;

  > .imageContainer {
    background-color: $nation-color;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: right;
    top: 0;
    width: 60%;
    z-index: -1;

    &.isPortrait {
      height: 60%;
      width: 100%;
    }

    > img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
  }

  > .mainContainer {
    background: background-gradient($nation-color, left);
    height: 100%;
    width: 100%;
    z-index: 9;

    &.business {
      background: background-gradient($business-color, left);
    }

    &.entertainment {
      background: background-gradient($entertainment-color, left);
    }

    &.science {
      background: background-gradient($science-color, left);
    }

    &.sports {
      background: background-gradient($sports-color, left);
    }

    &.world {
      background: background-gradient($world-color, left);
    }

    &.isPortrait {
      background: background-gradient($nation-color, top);

      &.business {
        background: background-gradient($business-color, top);
      }

      &.entertainment {
        background: background-gradient($entertainment-color, top);
      }

      &.science {
        background: background-gradient($science-color, top);
      }

      &.sports {
        background: background-gradient($sports-color, top);
      }

      &.world {
        background: background-gradient($world-color, top);
      }
    }

    > .descriptionContainer {
      bottom: 5%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 90%;
      overflow: hidden;
      position: absolute;
      right: 5%;
      width: 30%;

      &.isPortrait {
        height: 30%;
        width: 90%;
      }

      > .newsCategory {
        background-color: white;
        border-radius: 6px;
        font-size: 35px;
        padding: 12px;
        width: fit-content;

        &.none {
          display: none;
        }
      }

      > .date {
        color: white;
        font-size: 35px;
        margin-top: 50px;

        &.isPortrait {
          margin-top: 30px;
        }
      }

      > .title {
        color: white;
        font-size: 48px;
        margin-top: 50px;

        &.isPortrait {
          margin-top: 30px;
        }
      }

      > .description {
        color: white;
        flex: 1 1 auto;
        font-family: 'Apercu Light';
        font-size: 28px;
        line-height: 38px;
        margin-top: 50px;
        overflow: clip;

        &.isPortrait {
          margin-top: 30px;
        }
      }
    }
  }
}
