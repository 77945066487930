.Timer {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  > .innerWrapper {
    display: flex;
  }

  > .darkBackground {
    background: rgba(#000, 0.6);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &.hide {
      display: none;
    }
  }
}
