.ClockAnalog {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  > .react-clock > .react-clock__face {
    background: #fff;
    border-width: 10px;

    > .react-clock__mark > .react-clock__mark__number {
      font-size: 35px;
    }
  }
}
