.Stopwatch {
  align-items: center;
  color: #fff;
  display: flex;
  // height: 100%;
  // height: 50px;
  // width: 50px;
  // width: inherit;
  justify-content: center;
  padding: 0 50px;

  &.isPortrait {
    flex-direction: column;

    > .laps,
    > .time {
      height: 50%;
    }

    > div {
      margin: 20px 0;
      width: 100%;

      &.laps {
        margin-bottom: 0;
      }
    }
  }

  > div {
    width: 50%;

    &.time {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;

      > .innerWrapper {
        position: relative;

        > div,
        > div > div {
          > .react-clock {
            > .react-clock__face {
              background: #fff;
              color: #000;

              > .react-clock__mark > .react-clock__mark__number {
                padding: 10px;
              }
            }
          }

          &.bigClock > .react-clock {
            > .react-clock__hand {
              z-index: 1;
            }

            > .react-clock__face > .react-clock__mark > .react-clock__mark__number {
              font-size: 35px;
            }
          }

          &.smallClocks {
            display: flex;
            justify-content: center;
            left: 0;
            position: absolute;
            right: 0;
            top: 200px;

            > div {
              color: #000;
              margin: 0 40px;

              > .react-clock > .react-clock__face {
                border-width: 0;
              }

              > div {
                font-size: 20px;
                padding-top: 10px;
                text-align: center;
              }
            }
          }

          &.digital {
            align-items: center;
            bottom: 175px;
            color: #000;
            display: flex;
            flex-direction: column;
            left: 0;
            position: absolute;
            right: 0;

            > .sevenSegmentDigits {
              display: flex;
            }

            > .milliseconds {
              font-size: 25px;
              text-align: right;
              width: 300px;
            }
          }
        }
      }
    }

    &.laps {
      height: 230px;
      margin-bottom: 500px;

      > table {
        border-collapse: collapse;
        font-size: 40px;
        padding: 100px;
        width: 100%;

        > thead {
          border-bottom: 2px solid #fff;
        }

        > tbody::before {
          margin: 100px;
        }

        > tbody > tr {
          height: 30px;
        }
      }
    }
  }
}
