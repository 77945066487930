.Weather {
  align-items: center;
  font-family: 'Apercu Bold';
  height: 100%;
  margin: auto;
  width: 100%;

  > .message {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 160px;
    height: 100%;
    justify-content: center;
    padding: 80px;
    text-align: center;
  }

  > .lastUpdated {
    color: #fff;
    display: flex;
    font-size: 25px;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    > div {
      padding: 5px 20px;
    }
  }

  > .weather {
    align-items: flex-start;
    display: flex;
    flex: wrap;
    flex-direction: column;
    gap: 50px;
    height: 100%;
    justify-content: space-between;
    margin: auto;
    width: 100%;

    > .currentWeather {
      align-items: center;
      color: #fff;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 10px 80px 0;
      width: calc(100% - 80px * 2);

      > .primaryInfoContainer {
        align-items: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 35%;

        > .city {
          font-size: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        > .currentTemp {
          font-size: 150px;
        }

        > .dateContainer {
          align-items: center;
          display: flex;
          font-size: 35px;

          > .today {
            align-items: center;
            background: #fcfcfc;
            border-radius: 6px;
            color: #0d0d0d;
            display: flex;
            height: 55px;
            justify-content: center;
            width: 130px;
          }

          > .date {
            margin-left: 40px;
          }
        }
      }

      > .weatherInfoContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 35%;

        > img:first-of-type {
          height: auto;
          width: 300px;
        }

        > .description {
          font-size: 50px;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }

      > .optional {
        align-items: right;
        color: white;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 35%;

        > .optionalInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;

          > span {
            font-size: 40px;
            line-height: 2em;
            overflow: hidden;
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    > .forecast {
      align-items: center;
      color: white;
      display: flex;
      justify-content: space-between;
      padding: 20px 80px;
      width: calc(100% - 80px * 2);

      > .dailyForecastContainer {
        align-items: center;
        background: rgba(#000, 0.2);
        border-radius: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 340px;

        > img:first-of-type {
          height: auto;
          width: 180px;
        }

        > .dayOfWeek {
          font-size: 55px;
          margin-top: 5px;
        }

        > .description {
          font-size: 45px;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 95%;
        }

        > .temperature {
          display: flex;
          font-size: 40px;
          justify-content: space-around;
          margin-bottom: 15px;
          width: 95%;

          > .max {
            color: salmon;
          }

          > .min {
            color: rgb(170 236 246);
          }
        }
      }

      > .hourlyForecastContainer {
        align-items: center;
        background: rgba(#000, 0.2);
        border-radius: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
        width: 240px;

        > img:first-of-type {
          height: auto;
          padding: 20px 0;
          width: 180px;
        }

        > .time {
          font-size: 50px;
        }

        > .description {
          font-size: 32px;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 95%;
        }

        > .temperature {
          font-size: 40px;
        }
      }
    }

    &.isPortrait {
      align-items: center;
      display: flex;
      justify-content: center;

      > .currentWeather {
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 60px 80px;

        > .primaryInfoContainer {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 100%;

          > .city {
            font-size: 120px;
            text-align: center;
          }
        }

        > .weatherInfoContainer {
          padding-top: 60px;
        }

        > .optional {
          height: auto;
          padding-top: 100px;
        }
      }

      > .forecast {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 50px;
        width: max-content;

        > .dailyForecastContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 10px;
          padding: 30px 40px;

          > .timeAndTemp {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 160px;

            > .dayOfWeek {
              font-size: 45px;
              padding-bottom: 20px;
            }

            > .temperature {
              display: flex;
              flex-direction: column;
              font-size: 40px;
              text-align: center;

              > .max {
                color: salmon;
              }

              > .min {
                color: rgb(170 236 246);
              }
            }
          }

          > .iconAndDescription {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 10px;

            > img:first-of-type {
              height: auto;
              width: 160px;
            }

            > .description {
              font-size: 34px;
              line-height: 0.8;
              overflow-wrap: break-word;
              padding: 0 10px;
              text-align: center;
              width: 140px;
            }
          }
        }

        > .hourlyForecastContainer {
          display: flex;
          flex-direction: row;
          margin: 10px;
          padding: 30px 40px;
          width: max-content;

          > .timeAndTemp {
            display: flex;
            flex-direction: column;
            width: 160px;

            > .time {
              font-size: 55px;
              padding-bottom: 20px;
            }

            > .temperature {
              font-size: 40px;
              text-align: center;
            }
          }

          > .iconAndDescription {
            display: flex;
            flex-direction: column;
            padding: 10px;

            > img:first-of-type {
              height: auto;
              width: 160px;
            }

            > .description {
              font-size: 34px;
              line-height: 0.8;
              overflow-wrap: break-word;
              padding: 0 10px;
              text-align: center;
              width: 140px;
            }
          }
        }
      }
    }
  }
}
