.ClockDigital {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;

  > .time {
    display: flex;

    > div {
      display: flex;
    }
  }

  > .meridiem {
    display: flex;
    flex-direction: column;
    font-size: 60px;
    height: 200px;
    justify-content: space-between;
    margin-right: 35px;

    > div {
      &.inactive {
        opacity: 0.2;
      }
    }
  }

  &.small {
    > .meridiem {
      font-size: 30px;
      height: 100px;
      margin-right: 10px;
    }
  }
}
