.CalendarDay {
  box-sizing: border-box;
  color: white;
  font-family: 'Apercu Pro Regular';
  height: 100%;
  padding: 90px 200px 90px 90px;
  width: 100%;

  &.isPortrait {
    padding: 90px;
  }

  > .mainContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    &.isPortrait {
      flex-direction: column;
    }

    > .headerContainer {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 900px;
      justify-content: center;
      line-height: 1;
      text-align: center;
      width: 730px;

      &.isPortrait {
        height: 450px;
        width: 900px;
      }

      > .header {
        margin: 0 90px 0 0;

        &.isPortrait {
          margin: 0 0 90px;
        }

        > .date {
          font-size: 48px;
          margin: 0 0 60px;
        }

        > .day {
          font-size: 60px;
          margin: 0 0 10px;
        }

        > .time {
          font-size: 80px;
          font-weight: 700;
        }
      }
    }

    > .eventsSection {
      height: 900px;
      width: 900px;

      &.isPortrait {
        height: 1200px;
      }

      > .errorContainer {
        display: flex;
        flex-flow: column wrap;
        height: 900px;
        justify-content: center;
        width: 900px;

        &.isPortrait {
          height: 1200px;
        }

        > .error {
          text-align: center;

          > .errorImage {
            height: 48px;
            margin: 0 0 20px;
            width: 48px;
          }

          > .errorMessage {
            font-size: 40px;
          }
        }
      }

      > .noErrorSection {
        > .eventsContainer {
          display: flex;
          flex-flow: column wrap;
          height: 750px;
          justify-content: center;
          width: 900px;

          &.isPortrait {
            height: 1050px;
          }

          &.twoColumns {
            justify-content: start;
          }

          > .event {
            background: rgba(255 255 255 / 20%);
            border-radius: 4px;
            margin: 8px 16px 8px 0;
            padding: 30px;
            width: 383px;

            &.long {
              margin: 8px 0;
              width: 840px;
            }

            &.now {
              background: #0082ff;
            }

            > .eventTime {
              font-size: 24px;
            }

            > .eventTitle {
              font-size: 40px;
              margin: 0 0 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          > .noEvents {
            text-align: center;

            > .noEventsImage {
              height: 48px;
              margin: 0 0 20px;
              width: 48px;
            }

            > .noEventsMessage {
              font-size: 40px;
            }
          }
        }

        > .line {
          border-bottom: 2px solid white;
          margin: 0 0 8px;
        }

        > .subtitle {
          font-size: 32px;
          margin: 0 0 20px;
        }
      }
    }
  }
}
