.Clock {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0;

  > .date {
    color: #fff;
    font-size: 50px;
    margin-top: 50px;
  }

  > .colon {
    font-size: 300px;
    position: absolute;
  }

  > .clock {
    align-items: center;
    display: flex;
    justify-content: center;

    > .hybrid {
      display: flex;

      &.isPortrait {
        flex-direction: column;

        > div {
          padding: 75px 0;
        }
      }

      > div {
        padding: 0 50px;
      }
    }
  }
}
